import ArticleLink from "../article-link/ArticleLink"
import ArticleSection from "../article-section/ArticleSection"
import ContentContainer from "../content-container/ContentContainer"
import { Heading } from "dnb-ui-lib/components"
import { PropTypes } from "prop-types"
import React from "react"
import * as styles from "./RelatedArticles.module.scss"

/**
 * Returns a grid displaying article-links to related articles
 * @param {relatedArticles} array Article-data from Sanity that is used to generate links for the grid.
 *
 */

const RelatedArticles = ({ relatedArticles }) => {
  const articles = relatedArticles.map(
    ({ id, articleTitle, slug, imageCover, articleCategory, hideArticle }) => (
      <ArticleLink
        key={id}
        title={articleTitle}
        link={slug.current}
        image={imageCover.image}
        category={articleCategory}
        hidden={hideArticle}
      />
    )
  )
  return (
    <ArticleSection theme={"teal"}>
      <ContentContainer>
        <div className={styles.related}>
          <Heading className={styles.title}>Se også</Heading>
          <div className={styles.articles}>{articles}</div>
        </div>
      </ContentContainer>
    </ArticleSection>
  )
}

RelatedArticles.propTypes = {
  relatedArticles: PropTypes.array.isRequired,
}

RelatedArticles.defaultProps = {
  relatedArticles: null,
}

export default RelatedArticles
