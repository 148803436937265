import { Anchor, Heading } from "dnb-ui-lib"

import ArticleSection from "../article-section/ArticleSection"
import ContentContainer from "../content-container/ContentContainer"
import { PropTypes } from "prop-types"
import React from "react"
import * as styles from "./ContactInfo.module.scss"

/**
 * Returns a component displaying the contact-person added to an article
 * @param {name} string The name displayed
 * @param {email} string The email displayed
 *
 */

const ContactInfo = ({ name, email }) => {
  return (
    <ArticleSection>
      <ContentContainer>
        <div className={styles.contact}>
          <Heading className={styles.title}>Kontaktperson</Heading>
          <div>{name}</div>
          <Anchor href={`mailto:${email}`}>{email}</Anchor>
        </div>
      </ContentContainer>
    </ArticleSection>
  )
}

ContactInfo.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
}

ContactInfo.defaultProps = {
  name: null,
  email: null,
}

export default ContactInfo
