import ArticleSection from "../article-section/ArticleSection"
import ContentContainer from "../content-container/ContentContainer"
import { PropTypes } from "prop-types"
import React from "react"
import * as styles from "./SidebarContent.module.scss"

/**
 * Returns a container for displaying two components in a sidebar layout
 * @param {object} main The content that will be displayed to the left
 * @param {object} aside The content that will be displayed in the sidebar
 *
 */

const SidebarContent = ({ main, aside }) => {
  return (
    <ArticleSection>
      <ContentContainer>
        <div className={styles.intro}>
          <div>{main}</div>
          <aside className={styles.sidebar}>{aside}</aside>
        </div>
      </ContentContainer>
    </ArticleSection>
  )
}

SidebarContent.propTypes = {
  main: PropTypes.object.isRequired,
  aside: PropTypes.object.isRequired,
}

SidebarContent.defaultProps = {
  main: null,
  aside: null,
}

export default SidebarContent
