import ArticleBuilder from "../components/article-builder/ArticleBuilder"
import ArticleHeader from "../components/article-header/ArticleHeader"
import ArticleIntro from "../components/article-intro/ArticleIntro"
import ContactInfo from "../components/contact-info/ContactInfo"
import Footer from "../components/footer/Footer"
import Layout from "../components/layout/Layout"
import React from "react"
import RelatedArticles from "../components/related-articles/RelatedArticles"
import SEO from "../components/seo/Seo"
import getArticleSEO from "../utils/getArticleSEO"
import { graphql } from "gatsby"

const StandardArticle = ({ data }) => {
  const article = data.standardArticle
  const articleSEO = getArticleSEO(article)

  return (
    <Layout>
      <SEO
        title={articleSEO.title}
        description={articleSEO.description}
        image={articleSEO.image}
        article={true}
      />
      <article>
        <ArticleHeader
          title={article.articleTitle}
          published={article._updatedAt}
          credit={article.articleCredits}
          media={article.imageCover}
        />
        <ArticleIntro
          ingress={article._rawArticleLede}
          relatedArticles={article.relatedArticles}
        />
        <ArticleBuilder sections={article._rawArticleBody} />
        {article.contactPerson && (
          <ContactInfo
            name={article.contactPerson.fullName}
            email={article.contactPerson.email}
          />
        )}
        {article.relatedArticles.length > 0 && (
          <RelatedArticles relatedArticles={article.relatedArticles} />
        )}
        <Footer />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query StandardArticleQuery($id: String!) {
    standardArticle: sanityArticleBasic(id: { eq: $id }) {
      ...StandardArticle
    }
  }
`

export default StandardArticle
