import ArticleLink from "../article-link/ArticleLink"
import ArticleSection from "../article-section/ArticleSection"
import ContentContainer from "../content-container/ContentContainer"
import { Heading } from "dnb-ui-lib/components"
import PortableText from "@sanity/block-content-to-react"
import { PropTypes } from "prop-types"
import React from "react"
import SidebarContent from "../sidebar-content/SidebarContent"
import * as styles from "./ArticleIntro.module.scss"

/**
 * Returns a styled component used to begin the article content, displaying the ingress and a sidebar with related articles
 * @param {ingress} string Main content, added as portable-text
 * @param {relatedArticles} object A list of related articles, that will be displayed as article-links in the sidebar
 *
 */

const ArticleIntro = ({ ingress, relatedArticles }) => {
  const MainContent = () => {
    if (ingress) {
      return (
        <div className={styles.text}>
          <PortableText blocks={ingress} />
        </div>
      )
    }
    return null
  }
  if (relatedArticles && relatedArticles.length > 0) {
    const articleList = () => {
      return (
        <div className={styles.articleList}>
          <Heading className={styles.title}>Relatert innhold</Heading>
          <div className={styles.articlesContainer}>{articles}</div>
        </div>
      )
    }
    const articles = relatedArticles.map(
      ({
        id,
        articleTitle,
        slug,
        imageCover,
        articleCategory,
        hideArticle,
      }) => (
        <ArticleLink
          key={id}
          type={"small"}
          title={articleTitle}
          link={slug.current}
          image={imageCover.image}
          category={articleCategory}
          hidden={hideArticle}
        />
      )
    )
    return <SidebarContent main={MainContent()} aside={articleList()} />
  } else {
    return (
      <ArticleSection>
        <ContentContainer>
          <MainContent />
        </ContentContainer>
      </ArticleSection>
    )
  }
}

ArticleIntro.propTypes = {
  ingress: PropTypes.array.isRequired,
  relatedArticles: PropTypes.array,
}

ArticleIntro.defaultProps = {
  ingress: null,
  relatedArticles: null,
}

export default ArticleIntro
